import React from "react";
import { BrowserRouter as Router, Route, Routes, Redirect } from "react-router-dom";
import './App.css'
// elements
import Cadastro from './pages/cadastro'
import Validacao from './pages/validacao'
import Login from './pages/login'
import Home from './pages/home/index.jsx'
import Recuperar from './pages/recuperar/index.jsx'

function App() {
  return (
    <>
     <Router>
      <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/home" element={ <Home /> } />
          <Route path="/cadastro" element={ <Cadastro /> } />
          <Route path="/login" element={ <Login /> } />
          <Route path="/validacao" element={ <Validacao /> } />
          <Route path="/recuperar" element={ <Recuperar /> } />
      </Routes>
    </Router>
    </>
  );
}

export default App;
