import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import '../styles.css'
import { 
  DivCentral, 
  InputGolden,
  BoxCad,
  Label,
  BTN2,
  BoxTxtCad,
  TopBarCad,
  LogoTopCad,
  Steps, StepsOff, StepsOn,
  AvisoErro
} from '../styles'
import { VerifyRegister } from '../../back/verifications'

import imgLogoTop from '../../imgs/mini-logo-top.png'

const Cadastro = () => {


  const [ HomeMail, SetHomeMail ] = useState('')
  const [ HomeCPF, SetHomeCPF ] = useState('')

  const [ CadNome, SetCadNome ] = useState('')
  const [ CadDate, SetCadDate ] = useState('')
  const [ Senha, SetSenha ] = useState('')


  const [ ConfMail, SetConfMail ] = useState('')
  const [ ConfSenha, SetConfSenha ] = useState('')


  const HandleInput = ( e, state, txt) => {
    state(e.target.value)
    try {
      if (txt !== "") { localStorage.setItem( txt, e.target.value ) }
    }
    catch (error) {
        //console.log("sem dados")
    }
  }

  const CheckInputs = () => {
    try {
      SetHomeMail(localStorage.getItem('homemail'))
      SetHomeCPF(localStorage.getItem('homecpf'))
      SetCadNome(localStorage.getItem('cadnome'))
      SetCadDate(localStorage.getItem('caddate'))
    }
    catch (error) {
        //console.log(error)
        //console.log("sem dados para preencher.")
    }
  }

  useEffect(() => {
    CheckInputs()
  }, [])


  const NextPage2 = async () => {
    let res = await VerifyRegister(
      CadNome, 
      Senha, 
      ConfSenha, 
      HomeMail, 
      ConfMail, 
      HomeCPF, 
      CadDate
    )

    try {
      if (res.status === 'sucesso') {
        alert('Cadastro realizado com sucesso!')
        window.location="/validacao"
      }
    }
    catch (error) {
      //console.log(error)
      alert('Não foi possível realizar o cadastro!')
     }
  }

  const [ AvisoSenha, SetAvisoSenha ] = useState(false)

    return (
      <>

        <TopBarCad>
          <LogoTopCad><a href="https://www.k10i.com.br/" target="_blank"><img src={ imgLogoTop } style={{width:"100px"}}></img></a></LogoTopCad>
        </TopBarCad>

        <DivCentral>
          <BoxCad>
          <div>

            <BoxTxtCad> COMPLETE SEU CADASTRO </BoxTxtCad>

            <Steps> <StepsOn style={{ left: '0'}} /> <StepsOff style={{ right: '0'}} /> </Steps>

            <form>
              <div>
                <Label> Nome Completo </Label>
                <InputGolden
                className="ipt" 
                placeholder="Seu Nome" 
                value={ CadNome } 
                onChange={ (e)=> HandleInput( e, SetCadNome, 'cadnome' ) } />
              </div>
              <div>
                <Label> Data de Nascimento </Label>
                <InputGolden
                className="ipt"
                placeholder="25/05/1989" 
                value={ CadDate } 
                onChange={ (e)=> HandleInput( e, SetCadDate, 'caddate' ) }/>
              </div>
              <div>
                <Label> CPF </Label>
                <InputGolden
                className="ipt"
                placeholder="123.456.789-00" 
                value={ HomeCPF } 
                onChange={ (e)=> HandleInput( e, SetHomeCPF, 'homecpf' ) } />
              </div>
              <div>
                <Label> E-mail </Label>
                <InputGolden
                className="ipt"
                placeholder="email@email.com.br"
                value={ HomeMail } 
                onChange={ (e)=> HandleInput( e, SetHomeMail, 'homemail' ) } />
              </div>
              <div>
                <Label> Confirmação de E-mail </Label>
                <InputGolden
                className="ipt"
                placeholder="email@email.com.br" 
                onChange={ (e)=> HandleInput( e, SetConfMail, "" ) } />
              </div>
              <div>
                <Label> Senha </Label>
                <InputGolden
                type="password"
                className="ipt"
                placeholder="xxxxxxxxxxxxx"
                autoComplete="none"
                onChange={ (e)=> HandleInput( e, SetSenha, "" ) } 
                onFocus={ (e)=> SetAvisoSenha(true) }/>
              </div>

              { AvisoSenha && 
              <AvisoErro>
                  OBS: A senha deve conter pelo menos 7 dígitos, letras maiúsculas, letras minúsculas e números.
              </AvisoErro> }

              <div>
                <Label> Confirmação de Senha </Label>
                <InputGolden
                type="password"
                className="ipt"
                placeholder="xxxxxxxxxxxxx"
                autoComplete="none"
                onChange={ (e)=> HandleInput( e, SetConfSenha, "" ) } />
              </div>
            </form>
            <BTN2 onClick={ (e)=> NextPage2() }> PRÓXIMO </BTN2>
            
          </div>
          </BoxCad>
        </DivCentral>

      </>
    );
  }
  
  export default Cadastro;
  