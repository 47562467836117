import { VerifyEmail as BackVerifyEmail, VerifyCpf as BackVerifyCPF, Register as ApiRegister} from "./back"

export const OnlyNumbersInString = (string) => {
  return string.replace(/[^0-9]/g,'')
}

export const TestaCPF = (strCPF) => {
    var Soma;
    var Resto;
    Soma = 0;
    var i;
    if (strCPF == "00000000000") return false;
    for (i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;
    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export const VerifyRegister = async (userName, password1, password2, email1, email2, cpf, birthday) => {
  
  if (userName === '' || userName === null) {
    alert('Nome não está preenchido.')
    return
  }

  if (birthday === '' || birthday === null) {
    alert('Data de nascimento não está preenchida.')
    return
  }

  if (cpf === '' || cpf === null) {
    alert('CPF não está preenchido.')
    return
  }

  if (email1 === '' || email1 === null) {
    alert('E-mail não está preenchido.')
    return
  }

  if (email2 === '' || email2 === null) {
    alert('Confirmar e-mail não está preenchido.')
    return
  }

  if (password1 === '' || password1 === null) {
    alert('Senha não está preenchida.')
    return
  }

  if (password2 === '' || password2 === null) {
    alert('Confirmar senha não está preenchida.')
    return
  }

  let userFirstName = userName.charAt(0)
  let userLastName = userName.charAt(userName.length - 1)

  if (userName.length < 5 ||
      !userName.match(" ") ||
      userFirstName === " " ||
      userLastName === " ") {
      //console.log('Nome não está preenchido corretamente.')
      alert('Nome não está preenchido corretamente.')
    return
  }

  if (password1.length < 7 ||
      !password1.match(/[A-Z]/) ||
      !password1.match(/[a-z]/) ||
      !password1.match(/\d/)) {
    //console.log('A senha deve conter pelo menos 7 dígitos, letras maiúsculas, letras minúsculas e números.')
    alert('A senha deve conter pelo menos 7 dígitos, letras maiúsculas, letras minúsculas e números.')
    return
  }

  if (password1 !== password2) {
    //console.log('As senhas devem ser iguais.')
    alert('As senhas devem ser iguais.')
    return
  }

  if (email1.indexOf("@") === -1 & email1.length > 0 || email1.indexOf(".") === -1 & email1.length > 0)
  {
    //console.log('E-mail inválido.')
    alert('E-mail inválido.')
    return
  }

  if (email1 !== email2) {
    //console.log('Os e-mails devem ser iguais.')
    alert('Os e-mails devem ser iguais.')
    return
  }

  let emailRes = await BackVerifyEmail(email1, email2)
  if (emailRes.status !== 'sucesso')
  {
    //console.log(emailRes.msn)
    alert(emailRes.msn)
    return
  }

  let cpfFinal = OnlyNumbersInString(cpf)
  if (!TestaCPF(cpfFinal)) {
    //console.log('CPF inválido.')
    alert('CPF inválido.')
    return
  }

  let cpfRes = await BackVerifyCPF(cpfFinal)
  if (cpfRes.status !== 'sucess') {
    //console.log(cpfRes.msn)
    alert(cpfRes.msn)
    return
  }

  if (OnlyNumbersInString(birthday).length !== 8) {
    //console.log('Data de nascimento inválida.')
    alert('Data de nascimento inválida.')
    return
  }

  let birth = OnlyNumbersInString(birthday)

  let day = birth[0] + birth[1]
  if (day < 1 || day > 31) {
    //console.log('Data de nascimento inválida.')
    alert('Data de nascimento inválida.')
    return
  }

  let month = birth[2] + birth[3]
  if (month < 1 || month > 12) {
    //console.log('Data de nascimento inválida.')
    alert('Data de nascimento inválida.')
    return
  }

  let year = birth[4] + birth[5] + birth[6] + birth[7]

  let niver = year + '-' + month + '-' + day

  let registerData = {
    nome: userName,
    Password: password1,
    email: email1,
    email2: email2,
    cpf: cpfFinal,
    niver: niver
  }

  let registerRes = await ApiRegister(registerData)

  if (registerRes.status !== 'sucesso') {
    //console.log(registerRes.msn)
    alert(registerRes.msn)
    return
  }
  
  return registerRes
}