import axios from "axios";

const promoCode = 'Vale10nak10'

export const VerifyEmail = async (email1, email2) => {
    return await Api5000Post(`verify_email?email=${email1}&email2=${email2}`)
}

export const VerifyCpf = async (cpfFinal) => {
    return await Api5000Get(`verify_cpf/${cpfFinal}`)
}

export const Register = async (fields) => {
    let form = new FormData()
    form.append( "nome", fields.nome )
    form.append( "Password", fields.Password )
    form.append( "email", fields.email )
    form.append( "email2", fields.email2 )
    form.append( "telefone", '' )  //vazio
    form.append( "cpf", fields.cpf )
    form.append( "rg", '')  //vazio
    form.append( "niver", fields.niver )
    form.append( "promotion_coupon", promoCode )

    return await Api5000Post('cadastro', form)
}

export const Validate = async (email, code) => {
    let form = new FormData()
    form.append( "email", email )
    form.append( "code", code )

    return await Api5000Post('confirmate_user', form)
}

const Api5000Get = async (endpoint) => {
    let response

    await axios.get(process.env.REACT_APP_BACK_USER_API_5000 + endpoint)
    .then((res) => {
        //console.log(res.data)
        response = res.data
    })
    .catch((error) => {
        //console.log(error)
        response = error
    })

    return response
}

const Api5000Post = async (endpoint, form) => {
    let response

    await axios.post(process.env.REACT_APP_BACK_USER_API_5000 + endpoint , form)
    .then((res) => {
        //console.log(res.data)
        response = res.data
    })
    .catch((error) => {
        //console.log(error)
        response = error
    })

    return response
}
