import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import '../styles.css'
import { 
  DivCentral, 
  InputGolden,
  BoxCad,
  Label,
  BTN2,
  BoxTxtCad, BoxTxtCadMini,
  TopBarCad,
  LogoTopCad,
  Steps, StepsOff, StepsOn
} from '../styles'
import { Validate } from '../../back/back';

import imgLogoTop from '../../imgs/mini-logo-top.png'
import { OnlyNumbersInString } from '../../back/verifications';

const Validacao = () => {

    const [ HomeMail, SetHomeMail ] = useState('')
    const [ Code, SetCode ] = useState('')

    const CheckInputs = () => {
      try {
        SetHomeMail(localStorage.getItem('homemail'))
      }
      catch (error) {
          //console.log(error)
          //console.log("sem dados para preencher.")
      }
    }
  
    useEffect(() => {
      CheckInputs()
    }, [])

    const Validar = async () => {

      let code = OnlyNumbersInString(Code)

      if (code === '') {
        alert('Código não está preenchido.')
        return
      }

      if (code.length !== 6) {
        alert('Código inválido.')
        return
      }

      const res = await Validate(HomeMail, code)
      
      try{
        if (res.status !== 'sucesso')
        {
          if (JSON.stringify(res.code) === '\"Email não confirmado.\"')
            alert('Código inválido.')
          else
            alert(JSON.stringify(res.code))

          return
        }

        alert('conta confirmada com sucesso.')
        window.location="/login"
      } catch (error) { }
    }


    useEffect(() => {
      
      localStorage.removeItem('homemail');
      localStorage.removeItem('homecpf');
      localStorage.removeItem('caddate');
      localStorage.removeItem('cadnome');

    }, [])


    return (
      <>

        <TopBarCad>
          <LogoTopCad><a href="https://www.k10i.com.br/" target="_blank"><img src={ imgLogoTop } style={{width:"100px"}}></img></a></LogoTopCad>
        </TopBarCad>

        <DivCentral>
          <BoxCad>
            <div>
              
            <BoxTxtCad> Validação da conta </BoxTxtCad>

            <Steps> <StepsOn style={{ right: '0'}} /> <StepsOff style={{ left: '0'}} /> </Steps>

            <BoxTxtCadMini>
              Enviamos um código para o seu e-mail. <br></br>
              Insira o código abaixo para a verificação da sua conta.
            </BoxTxtCadMini>

                <div>
                  <Label> Código </Label>
                  <InputGolden
                  className="ipt"
                  placeholder="Código" 
                  value={ Code } 
                  onChange={ (e)=> { e.target.value=SetCode(e.target.value) } }/>
                </div>

                <BTN2 onClick={ (e)=> Validar() }> ENVIAR </BTN2>
            </div>
          </BoxCad>
        </DivCentral>

      </>
    );
  }
  
  export default Validacao;
  