import styled from 'styled-components';

export const LogoTop = styled.div `
    position: absolute;
    top: 45px;
    right: 15px;

    @media(max-width: 800px) {
        display: flex;
        margin: auto;
        position: relative;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
    }
`;

export const DivHome = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    align-items: top;
    padding-top: 45px;

    @media(max-width: 800px) {
        flex-wrap: wrap;
    }
`;

export const ColHome = styled.div `

    @media(max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
`;

export const DivLogin = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    align-items: center;
    padding-top: 25px;

    @media(max-width: 800px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;

export const DivHomeGrey = styled.div `
    background-color: #ededed;
    min-height: 500px;
    height: auto;
    padding-top:25px;
    padding-bottom:75px;
`;

export const DivHomeBlack = styled.div `
    background-color: #000;
    min-height: 500px;
    height: auto;
    padding-top:25px;
    padding-bottom:55px;
`;

export const DivOrange = styled.div `
    background-color: #f75708;
    min-height: 500px;
    height: auto;
    padding-top:75px;
    //padding-bottom:75px;
    display: flex;
    justify-content: center;
    align-items: end;

    @media(max-width: 800px) {
        flex-wrap: wrap;
    }
`;

export const ColOrange = styled.div `
    padding-bottom: 400px;

    @media(max-width: 800px) {
        padding-bottom: 0;
    }
`;

export const DivCentral = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Box = styled.div `
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 375px;
    height: auto;
    padding: 35px 35px;
    background-color: #0f0f0f;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #ededed;

    @media(max-width: 800px) {
        width: 90%;
        overflow: auto;
        padding: 25px 15px;
    }
`;

export const BoxTxt = styled.div `
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 15px;
`;

export const InputBlack = styled.input `
    border: 0;
    background-color: #0f0f0f;
    height: 40px;
    width: 300px;
    margin: 15px 15px;
    border-bottom: 2px solid #343434;
    padding-left: 15px;
    padding-right: 15px;
    color: #ededed;

    @media(max-width: 800px) {
        display: block;
        width: 80%;
        margin: 25px auto;
    }
`;

export const MiniBtn = styled.div ` 
    font-size: 10px;
    background-color: none;
    width: 35px;
    height: 35px;
    margin: auto;
    margin-right: 0;
    cursor: pointer;
    &:hover {
        background-color: #4c4b4b52;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    border-radius: 100px;
    border: 3px solid #2f2f2f;

    @media(max-width: 800px) {
        margin-bottom: 25px;
    }

`;

export const IcoArrow = styled.img `
    width: 20px;
`;

export const TT = styled.div `
    font-size: 45px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 15px;
    line-height: 50px;
    font-family: 'Open Sans', sans-serif;
`;

export const TTspan = styled.span `
    color: #e19c27;
`;

export const SubTT = styled.div `
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px;
`;

export const Footer = styled.div `
    width: 100%;
    height: 100px;
    background-color: #f75708;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-top: 15px;
    padding-left: 45px;
    padding-right:45px;

    @media(max-width: 800px) {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 25px;
        padding: 30px 10px;
    }
`;

export const FTlogo = styled.div `
    cursor: pointer;
    &:hover{

    }
`;

export const FTredes = styled.div `
    cursor: pointer;
    &:hover{

    }
`;


export const FAQ = styled.div `
    display: block;
    font-size: 45px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 15px;
    line-height: 50px;
    font-family: 'Open Sans',sans-serif;
    color: #f75708;
    padding-bottom: 75px;
    text-align: center;
`;

export const TitleGold = styled.div `
    display: block;
    font-size: 45px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 15px;
    line-height: 50px;
    font-family: 'Open Sans',sans-serif;
    color: #f75708;
    padding-bottom: 25px;
    text-align: center;
    color: #e19c27;
`;

export const FlexFaq = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 75px;
    flex-direction: column;

    @media(max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
`;

export const FaqFlexInside = styled.div `
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    grid-gap: 25px;
`;

export const NumbersFaq = styled.div `
    display: block;
    margin: auto;
    width: 150px;
    height: 150px;
    border: 5px solid #f75708;
    border-radius: 200px;
    font-size: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans',sans-serif;
    text-align: center;
    color: #f75708;
`;

export const NumbersFaq2 = styled.div `
    margin: auto;
    font-size: 75px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Open Sans',sans-serif;
    text-align: center;
    color: #fff;
    margin-bottom: 50px;
    width: 80px;
`;

export const GoldFaq = styled.div `
    text-align: left;
    font-size: 18px;
    font-family: 'Open Sans',sans-serif;
    font-weight: bold;
    color: #e19c27;
    width: 550px;
`;

export const BTN1 = styled.div `
    display: flex;
    margin: auto;
    width: 150px;
    height: 50px;
    background-color: #f75708;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 100px;
    cursor: pointer;
`;

export const LogoTopCad = styled.div `
    position: absolute;
    top: 28px;
    left: 35px;
`;

export const TopBarCad = styled.div `
    width: 100%;
    height: 100px;
    background-color: #f75708;
`;

export const InputGolden = styled.input `
    display: block;
    border: 0;
    background-color: transparent;
    height: 40px;
    width: 90%;
    max-width: 400px;
    margin: 20px 0;
    border-bottom: 2px solid #e19b26;
    padding-left: 15px;
    padding-right: 15px;
    color: #ededed;
`;

export const BoxCad = styled.div `
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 35px 35px;
    background-color: transparent;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #ededed;
    overflow: hidden;
`;

export const Label = styled.label `
    padding-left: 15px;
    padding-right: 15px;
`;

export const BoxTxtCad = styled.div `
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
    text-align: center;
    margin-bottom: 55px;
    font-family: 'Open Sans',sans-serif;
`;

export const BoxTxtCadMini = styled.div `
    font-size: 18px;
    font-weight: normal;
    padding: 10px;
    text-align: center;
    margin-bottom: 55px;
`;

export const BoxTxtLogMini = styled.div `
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    text-align: center;
    margin-bottom: 55px;
    text-transform: uppercase;
    padding-bottom: 25px;
    border-bottom: 2px solid #f75708;
`;

export const BTN2 = styled.div `
    display: flex;
    margin: auto;
    width: 200px;
    height: 50px;
    background-color: #f75708;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 100px;
    cursor: pointer;
`;

export const Steps = styled.div `
    position: relative;
    display: block;
    margin: auto;
    width: 100%;
    max-width: 400px;
    height: 2px;
    background-color: #e19b26;
    margin-bottom: 75px;
    z-index: 1;
`;

export const StepsOff = styled.div `
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #000;
    border: 2px solid #e19b26;
    border-radius: 100px;
    z-index: 2;
    top: -5px;
`;

export const StepsOn = styled.div `
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #e19b26;
    border: 2px solid #e19b26;
    border-radius: 100px;
    z-index: 2;
    top: -5px;
`;

export const BTN3 = styled.div `
    display: flex;
    margin: auto;
    margin-left: 0;
    width: 250px;
    height: 50px;
    background-color: #FFF;
    color: #F75708;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 50px;
    cursor: pointer;

    @media(max-width: 800px) {
        margin: auto;
        margin-top: 30px;
    }

`;

export const AvisoErro = styled.div `
    font-size: 13px;
    color: #ddd;
    background-color: #4d3817;
    border-color: #e7d7d7;
    margin: 20px auto;
    width: 80%;
    padding: 10px;
    border-radius: 10px;
`;

export const ReLink = styled.div `
    text-align: center;
    cursor: pointer;
    padding-top:10px;

    @media(max-width: 800px) {
    }

    &:hover{
        text-decoration: underline;
    }
    
`;

export const BtnHome = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 55px;
    max-width: 250px;
    background-color: #f75708;
    font-weight: bold;
    font-size: 22px;
    border-radius: 10px;
    margin-top: 100px;
    cursor: pointer;
`;