// quadra

import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import '../styles.css'
import { 
  LogoTop,
  DivBG, 
  DivLeft, 
  DivHome, ColHome,
  DivHomeGrey,
  DivHomeBlack,
  DivCentral, 
  InputBlack, 
  Box, 
  BoxTxt, 
  MiniBtn, 
  IcoArrow, 
  TT, 
  TTspan, 
  SubTT,
  FAQ, FlexFaq, NumbersFaq, GoldFaq, BTN1,
  TitleGold,
  Footer, FTlogo, FTredes,
  ReLink,
  BtnHome,
  FaqFlexInside,
  NumbersFaq2
} from '../styles'

import { OnlyNumbersInString, TestaCPF } from '../../back/verifications';

import imgArrow from '../../imgs/arrow.png'
import imgBgSmall from '../../imgs/bg-small.png'

import imgRedes1 from '../../imgs/redes-1.png'
import imgRedes2 from '../../imgs/redes-2.png'  
import imgRedes3 from '../../imgs/redes-3.png'

import imgLogoFT from '../../imgs/mini-logo-orange.png' 
import imgLogoTop from '../../imgs/mini-logo-black.png'

import imgBgLogin from '../../imgs/bg-login.png'

const ImgPlayer = styled.img `
  transform: translateY(4px);
  width: 100%;
  max-width: 500px;
`;


const Home = () => {

    const [ HomeMail, SetHomeMail ] = useState('')
    const [ HomeCPF, SetHomeCPF ] = useState('')

    const HandleInput = (e,state) => {
      state(e.target.value)
    }

    const CheckInputs = () => {
      try {
        SetHomeMail(localStorage.getItem('homemail'))
        SetHomeCPF(localStorage.getItem('homecpf'))
      }
      catch (error) {
          //console.log(error)
          //console.log("sem dados para preencher.")
      }
    }

    useEffect(() => {
      CheckInputs()
    }, [])


    const NextPage1 = () => {

      if (HomeMail === '' || HomeMail === null) {
        alert('E-mail não está preenchido.')
        return
      }

      if (HomeMail.indexOf("@") === -1 & HomeMail.length > 0 || HomeMail.indexOf(".") === -1 & HomeMail.length > 0)
      {
        alert('E-mail inválido.')
        return
      }

      if (HomeCPF === '' || HomeCPF === null) {
        alert('CPF não está preenchido.')
        return
      }

      let cpfFinal = OnlyNumbersInString(HomeCPF)
      if (!TestaCPF(cpfFinal)) {
        alert('CPF inválido.')
        return
      }

      localStorage.setItem( 'homemail', HomeMail )
      localStorage.setItem( 'homecpf', HomeCPF )
    }

    return (

      <>

        <LogoTop>
          <a href="https://www.k10i.com.br/" target="_blank"><img src={ imgLogoTop } style={{width:"150px"}}></img></a>
        </LogoTop>

        {/** top form */}

        <DivHome>
            <ColHome style={{paddingBottom: '50px'}}>

              <TT>
                <TTspan>A K10 PATROCINA SEU FUT!</TTspan>
              </TT>

              <SubTT>
                SIMPLES, RÁPIDO E SEM BUROCRACIA <br></br>
                CADASTRE SEU TIME E GANHE 10% DE DESCONTO
              </SubTT>


                <div>

                  <BtnHome onClick={ () => {window.location="/cadastro" }}> FAÇA SEU CADASTRO </BtnHome>

                </div>

                <SubTT style={{ marginTop: '100px' }} > SIGA O PASSO A PASSO E AS INSTRUÇÕES </SubTT>

            </ColHome>
            <div>
              <ImgPlayer src={ imgBgSmall }></ImgPlayer>
            </div>
          </DivHome>

          {/* FAQ */}
          <DivHomeGrey>
            
              <FAQ>COMO CONSEGUIR O DESCONTO?</FAQ>

              <FlexFaq>
                <FaqFlexInside>
                  <NumbersFaq> 1 </NumbersFaq>
                  <GoldFaq> Cadastre no mínimo 15 pessoas na plataforma na K10, a primeira bolsa de valores de jogadores de futebol do mundo </GoldFaq>
                </FaqFlexInside>
                <FaqFlexInside>
                  <NumbersFaq> 2 </NumbersFaq>
                  <GoldFaq> Mande um e-mail para quadra que você vai jogar com cópia para joao.tessler@k10invest.com contendo TODOS os nomes e CPFs dos cadastros </GoldFaq>
                </FaqFlexInside>
                <FaqFlexInside>
                  <NumbersFaq> 3 </NumbersFaq>
                  <GoldFaq> GANHE 10% de desconto para sua próxima mensalidade. A confirmação do desconto será feita em até 2 dias úteis </GoldFaq>
                </FaqFlexInside>
              </FlexFaq>

              <BTN1 onClick={ (e)=> window.scrollTo({top: 0, behavior: 'smooth'}) }>INVISTA AGORA</BTN1>

          </DivHomeGrey>


          <DivHomeBlack>

            <TitleGold> PASSO A PASSO </TitleGold>

            <DivHome>

              <div> 
                <ImgPlayer src={ imgBgLogin }></ImgPlayer>
              </div>
              <div>
                <FaqFlexInside>
                  <NumbersFaq2>1</NumbersFaq2>
                  <GoldFaq> Crie seu cadastro e valide sua conta </GoldFaq>
                </FaqFlexInside>

                <FaqFlexInside>
                  <NumbersFaq2>2</NumbersFaq2>
                  <GoldFaq> Mande para a K10 e para a quadra a lista e CPF dos cadastrados por e-mail </GoldFaq>
                </FaqFlexInside>

                <FaqFlexInside>
                  <NumbersFaq2>3</NumbersFaq2>
                  <GoldFaq> Receba em 2 dias úteis a aprovação do desconto para a próxima mensalidade </GoldFaq>
                </FaqFlexInside>
              </div>

            </DivHome>

          </DivHomeBlack>


          {/* Footer */}

          <Footer>
            <FTlogo>
              <a href="https://www.k10i.com.br/" target="_blank"> <img src={ imgLogoFT }></img> </a>
            </FTlogo>
            <FTredes>
                <a href="https://www.instagram.com/k10_invest/" target="_blank"> <img src={ imgRedes1 } ></img> </a>
                <a href="https://www.facebook.com/K10invest" target="_blank"> <img src={ imgRedes2 } ></img> </a>
                <a href="https://www.youtube.com/channel/UCLYjbhlVh8NkVGbzqEG_Y7w" target="_blank"> <img src={ imgRedes3 } ></img> </a>
            </FTredes>
          </Footer>

      </>

    );
  }
  
  export default Home;
  