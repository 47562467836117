import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components';
import '../styles.css'
import {
  LogoTop,
  DivLogin,
  Box, 
  InputBlack,
  MiniBtn,
  IcoArrow,
  BoxTxtLogMini,
  DivOrange, ColOrange,
  TT,
  TTspan,
  BTN3,
  TopBarCad,
  LogoTopCad,
  ReLink
} from '../styles'

import arrow from '../../imgs/arrow.png'
import imgBgLogin from '../../imgs/bg-login.png'
import imgBgOrange from '../../imgs/bg-orange.png'
import imgLogoTop from '../../imgs/mini-logo-top.png'
import imgLoader from '../../imgs/loader.gif'

const ImgPlayer = styled.img `
  transform: translateY(4px);
  width: 100%;
  max-width: 500px;
`;

const Loading = styled.div `
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #968c8c7f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Loader = styled.img `
  width: 125px;
`;

const Login = () => {

    const [ ClickLogin , SetClickLogin ] = useState(false)

    const [ InputLogin, SetInputLogin ] = useState('')
    const [ InputSenha, SetInputSenha ] = useState('')

    const ApiLogin = async () => {

      if ( ClickLogin === false ) {

          SetClickLogin(true);
          SetShowLoader(true)

          const form = new FormData();
          form.append('email', InputLogin);
          form.append('password', InputSenha);

          try {

              // pegar token
              const data1 = await axios.post(process.env.REACT_APP_BACK_USER_API_5000 + 'login', form, )
              //console.log(data1)
              const TokenId = data1.data.cooky.AuthenticationResult.AccessToken;
              const TokenURL = data1.data.url_to;
              localStorage.setItem('user_token', TokenId)
              localStorage.setItem('user_url', TokenURL)

              // pegar login
              const data2 = await axios.get(process.env.REACT_APP_HOST_API + 'users/me', { 
                headers: {Authorization: localStorage.getItem('user_token')} })
                //console.log(data2)
                const UserInfo = JSON.stringify(data2.data.data);
                localStorage.setItem('user_info', UserInfo)

              if ( 
                  localStorage.getItem('user_token') !== null && 
                  localStorage.getItem('user_token') !== '' && 
                  localStorage.getItem('user_url')   !== null && 
                  localStorage.getItem('user_url')   !== '' &&
                  localStorage.getItem('user_info')  !== null && 
                  localStorage.getItem('user_info')  !== ''
              ) {

                  
                  // criar token
                  document.cookie = "user_url="   + localStorage.getItem('user_url')     + ";domain=.k10i.com.br"
                  document.cookie = "user_token=" + localStorage.getItem('user_token')   + ";domain=.k10i.com.br"
                  document.cookie = "user_info="  + localStorage.getItem('user_info')   + ";domain=.k10i.com.br"

                  setTimeout(() => {
                    window.location = 'https://webapp.k10i.com.br/oportunidade'
                  }, 2000);

              }

          }
          catch {
              alert('Não foi possível realizar o login.')
              SetClickLogin(false);
          }
          
      }

    }

    const [ ShowLoader, SetShowLoader ] = useState(false)
    return (
      <>

        { ShowLoader && 
          <Loading> 
          <Loader src={ imgLoader }></Loader>
          </Loading>
        }

        <TopBarCad>
          <LogoTopCad><a href="https://www.k10i.com.br/" target="_blank"><img src={ imgLogoTop } style={{width:"100px"}}></img></a></LogoTopCad>
        </TopBarCad>

      <DivLogin>
        <div>
          <ImgPlayer src={ imgBgLogin }></ImgPlayer>
        </div>
        <div>
          <Box>
            <div>

              <BoxTxtLogMini> Acesse a Plataforma e Escolha seus Tokens </BoxTxtLogMini>

              <div>
                <InputBlack className="ipt" placeholder="E-mail" value={InputLogin} onChange={ (e)=> SetInputLogin(e.target.value) }/>
              </div>

              <div>
                <InputBlack type="password" className="ipt" placeholder="Senha" value={InputSenha} onChange={ (e)=> SetInputSenha(e.target.value) } />
              </div>

              <MiniBtn onClick={ (e)=> ApiLogin() }> <IcoArrow src={ arrow }></IcoArrow> </MiniBtn>

              <ReLink>
                    <a href="https://webapp.k10i.com.br/senha" target="_blank" style={{color:'#fff', textDecoration: 'none'}}>Esqueceu a senha? Crie uma nova</a>
              </ReLink>

            </div>
          </Box>
        </div>
      </DivLogin>

      <DivOrange>
        <div>

          <ColOrange>
            <TT style={{color:'#fff'}}>
              <TTspan style={{color:'#000'}}>Garanta</TTspan> já seus R$ 10 <br></br>
              e comece a  <TTspan style={{color:'#000'}}>investir na <br></br>
              sua paixão</TTspan>
            </TT>
            <BTN3 onClick={ (e)=> window.scrollTo({top: 0, behavior: 'smooth'}) }> PARTICIPE AGORA </BTN3>
          </ColOrange>

        </div>
        <div>
          <ImgPlayer src={imgBgOrange}></ImgPlayer>
        </div>
      </DivOrange>

      </>
    );
  }
  
  export default Login;
  