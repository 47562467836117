import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components';
import '../styles.css'
import { 
  LogoTop,
  DivBG, 
  DivLeft, 
  DivHome, 
  DivHomeGrey, 
  DivCentral, 
  InputBlack, 
  Box, 
  BoxTxt, 
  MiniBtn, 
  IcoArrow, 
  TT, 
  TTspan, 
  SubTT,
  FAQ, FlexFaq, NumbersFaq, GoldFaq, BTN1,
  Footer, FTlogo, FTredes,
  TopBarCad,LogoTopCad
} from '../styles'


import imgArrow from '../../imgs/arrow.png'
import imgBgSmall from '../../imgs/bg-small2.png'

import imgRedes1 from '../../imgs/redes-1.png'
import imgRedes2 from '../../imgs/redes-2.png'  
import imgRedes3 from '../../imgs/redes-3.png'

import imgLogoFT from '../../imgs/mini-logo-orange.png' 
import imgLogoTop from '../../imgs/mini-logo-top.png'

const ImgPlayer = styled.img `
  transform: translateY(4px);
  width: 100%;
  max-width: 500px;
`;

const Enviar = styled.div `
  display: block;
  margin: auto;
  width: 125px;
  height: 30px;
  background-color: #F75708;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;


const Recuperar = () => {

    const [ RecoverMail, SetRecoverMail ] = useState('')
    const [ RecoverCode, SetRecoverCode ] = useState('')

    const HandleInput = (e,state) => {
      state(e.target.value)
    }

    const ValidarCadastro = async () => {
    
      const FormData = require("form-data");
      const form = new FormData();

      form.append("email", RecoverMail);
      form.append("code", RecoverCode);

      const confirmation = await axios.post(process.env.REACT_APP_BACK_USER_API_5000 + "confirmate_user", form, )
      //console.log(confirmation)
      alert(confirmation.data.code);
      if (confirmation.data.status === "sucesso") {
        window.location="/login"
      }

    };

    const GerarCodigo = async () => {

      if ( RecoverMail !== '' && RecoverMail.indexOf('@')) {

        const codigo = await axios.post(process.env.REACT_APP_BACK_USER_API_5000 + 'resend_confirmation_code' + '?email=' + RecoverMail, )
        //console.log(codigo)

        if (codigo.data.status == "success") {
          alert(codigo.data.msn)
        } else {
          alert(codigo.data.msn)
        }

      } else { alert("E-mail inválido.")}

    }

    return (


      <>

        <TopBarCad>
          <LogoTopCad><a href="https://www.k10i.com.br/" target="_blank"><img src={ imgLogoTop } style={{width:"100px"}}></img></a></LogoTopCad>
        </TopBarCad>

        {/** top form */}

        <DivHome style={{paddingTop:'50px'}}>
          <div style={{paddingBottom: '50px'}}>
              <TT>
                Que bom que você voltou!
              </TT>

              <Box style={{ background: 'none'}}>

                <div>
                  
                  <BoxTxt style={{fontSize: '16px', textTransform: 'none'}}>
                    Para recuperar seu cadastro, informe o seu <br></br> e-mail para enviarmos um novo código.
                  </BoxTxt>

                  <div> 
                    <InputBlack
                    style={{backgroundColor:'transparent'}}
                    className="ipt" 
                    placeholder="E-mail"
                    value={ RecoverMail } 
                    onChange={ (e)=> HandleInput(e,SetRecoverMail) } />
                  </div>

                  <Enviar onClick={ (e)=> GerarCodigo() }> Enviar </Enviar>

                  <BoxTxt style={{fontSize: '16px', textTransform: 'none'}}>
                  Insira o código de recuperação.
                  </BoxTxt>

                  <div>
                    <InputBlack 
                    style={{backgroundColor:'transparent'}} 
                    className="ipt" 
                    placeholder="Código" 
                    value={ RecoverCode } 
                    onChange={ (e)=> HandleInput(e,SetRecoverCode) }/>
                  </div>

                  <Enviar onClick= { (e)=> ValidarCadastro() }> Enviar </Enviar>
                
                </div>

              </Box>

            </div>
            <div>
              <ImgPlayer src={ imgBgSmall }></ImgPlayer>
            </div>
          </DivHome>


      </>
    );
  }
  
  export default Recuperar;
  